<template>
  <div>
    <b-card class="sticky-header-control">
      <b-row>
        <b-col class="d-flex align-items-center" md="6">
          <h3 class="text-primary m-0 p-0">
            {{ $t("g.maintenanceRequest") }} |
          </h3>
          <b-breadcrumb :items="breadCrumbItems" />
        </b-col>
        <b-col md="6" class="justify-content-end d-flex">
          <b-button
            @click.prevent="validationForm"
            type="submit"
            variant="primary"
            >{{ $t("g.submit") }}</b-button
          >
        </b-col>
      </b-row>
    </b-card>
    <validation-observer ref="MaintenanceRequestForm">
      <b-form>
        <b-card :title="$t('g.basicInformations')">
          <b-row>
            <b-col md="4">
              <b-form-group :label="$t('g.notes')">
                <b-form-input
                  v-model="form_data.notes"
                  :placeholder="$t('g.placeholder.EnterTheNoteHere')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.maintenanceType')">
                <validation-provider
                  #default="{ errors }"
                  name="maintenanceType"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.maintenance_type_id"
                    :options="maintenance_type_options"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :state="errors.length > 0 ? false : null"
                    text-field="name"
                    value-field="id"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="4" v-if="isEdit">
              <b-form-group :label="$t('g.status')">
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.is_open"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :state="errors.length > 0 ? false : null"
                    :options="is_open_options"
                    text-field="text"
                    value-field="value"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col md="4">
              <b-form-group :label="$t('g.start_date')">
                <validation-provider
                  #default="{ errors }"
                  name="start date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.start_date"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group :label="$t('g.end_date')">
                <validation-provider
                  #default="{ errors }"
                  name="end date"
                  rules="required"
                >
                  <b-form-datepicker
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.end_date"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>
  
  <script>
import {
  BCard,
  BFormDatepicker,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BBreadcrumb,
  BCardHeader,
  BFormTextarea,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BCard,
    BFormDatepicker,
    BRow,
    BCol,
    BFormGroup,
    ValidationProvider,
    BFormTextarea,
    BForm,
    ValidationObserver,
    BFormInput,
    BButton,
    BBreadcrumb,
    BCardHeader,
    BFormSelect,
    BFormCheckbox,
    BFormFile,
  },
  data() {
    return {
      form_data: {
        device_id: null,
        maintenance_type_id: null,
        notes: null,
        start_date: null,
        end_date: null,
      },
      process_id: null,
      isEdit: null,
      is_open_options: [
        { value: null, text: this.$t("g.selectStatus"), disabled: true },
        { value: 0, text: this.$t("g.open") },
        { value: 1, text: this.$t("g.close") },
      ],
      breadCrumbItems: [
        {
          text: this.$t("g.home"),
          to: { name: "Home" },
        },
        {
          text: this.$t("g.maintenanceLog"),
          to: { name: "EquipmentCard" },
        },
        {
          text: this.$t("g.addMaintenanceRequest"),
          active: true,
        },
      ],
      maintenance_type_options: [
        {
          id: null,
          name: this.$t("g.selectMaintenanceType"),
          disabled: true,
        },
      ],
    };
  },
  beforeMount() {
    this.form_data.device_id = this.$route.params.id;
    this.getAllInfo();
    if (this.$route.params.process_id) {
      this.process_id = this.$route.params.process_id;
      this.isEdit = true;
      this.getInfoProcess(this.form_data.device_id, this.process_id);
      console.log(this.form_data);
    } else {
      this.isEdit = false;
    }
  },
  methods: {
    getAllInfo() {
      this.$http
        .get("admin/maintenanceTypes", {
          params: { is_cycle: 0, device_id: this.form_data.device_id },
        })
        .then((res) => {
          this.maintenance_type_options.push(...res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getInfoProcess(id, process_id) {
      this.$http
        .get(`admin/devices/${id}/maintainProcesses/${process_id}`)
        .then((res) => {
          if (res.status) {
            for (const key in res.data.data) {
              if (
                key === "notes" ||
                key === "start_date" ||
                key === "end_date"
              ) {
                this.form_data[key] = res.data.data[key];
              } else if (key === "is_open") {
                this.form_data.is_open = Number(res.data.data[key]);
              } else if (key === "maintenanceType") {
                this.form_data.maintenance_type_id = res.data.data[key].id;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    clearForm() {
      let form = this.form_data,
        i;
      for (i in form) {
        form[i] = null;
        if (i === "date") {
          form[i] = new Date();
        }
      }
    },
    validationForm() {
      console.log(this.form_data);
      this.$refs.MaintenanceRequestForm.validate().then((success) => {
        if (success) {
          let formData = new FormData();
          for (let item in this.form_data) {
            formData.append(item, this.form_data[item]);
          }

          if (this.isEdit) {
            this.$http
              .post(
                `admin/devices/${this.form_data.device_id}/maintainProcesses/${this.process_id}`,
                formData,
                {
                  params: { _method: "put" },
                }
              )
              .then((res) => {
                this.makeToast(
                  "success",
                  this.$t("g.send.successBody"),
                  this.$t("g.send.successTitle")
                );
                setTimeout(() => {
                  this.$router.push({ name: "EquipmentCard" });
                }, 1000);
                this.clearForm();
                this.$refs.MaintenanceRequestForm.reset();
              })
              .catch((err) => {
                for (const key in err.response.data.errors) {
                  this.makeToast(
                    "danger",
                    err.response.data.errors[key][0],
                    this.$t("g.send.errorTitle")
                  );
                }
                console.log(err);
              });
          } else {
            this.$http
              .post(
                `admin/devices/${this.form_data.device_id}/maintainProcesses`,
                formData
              )
              .then((res) => {
                this.makeToast(
                  "success",
                  this.$t("g.send.successBody"),
                  this.$t("g.send.successTitle")
                );
                setTimeout(() => {
                  this.$router.push({ name: "EquipmentCard" });
                }, 1000);
                this.clearForm();
                this.$refs.MaintenanceRequestForm.reset();
              })
              .catch((err) => {
                console.log(err.response.data.message);
                if (err.response.data.errors) {
                  for (const key in err.response.data.errors) {
                    this.makeToast(
                      "danger",
                      err.response.data.errors[key][0],
                      this.$t("g.send.errorTitle")
                    );
                  }
                } else {
                  this.makeToast(
                    "danger",
                    err.response.data.message,
                    this.$t("g.send.errorTitle")
                  );
                }
                console.log(err);
              });
          }
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
  },
};
</script>
  